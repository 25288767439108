import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import ApiConfig, { baseUrl } from "src/config/APICongig";
import { AuthContext } from "./Auth";

export const UserContext = createContext();
const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(false);
  const auth = useContext(AuthContext);
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [userBalanceData, setUserBalanceData] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [unReadNotification, setUnReadNotification] = useState([]);
  const [StatusData, setStatusData] = useState({});
  const [loader, setLoader] = useState(false);

  const accessToken = localStorage.getItem("creatturAccessToken");
  // useEffect(() => {
  //   socket.on("connection", function () {
  //     console.log("---connected---");
  //   });
  //   return () => {
  //     socket.close();
  //   };
  // }, []);

  const getWalletHandler = async (accessToken) => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.myWallet,
        headers: {
          // token: accessToken,
          authorization: `Bearer ${accessToken}`,
        },
      });
      //  console.log("conin list ",res)
      if (res.data.status === 200) {
        // window.localStorage.getItem("coinName");
        setUserBalanceData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const NotificationDataList = async (token) => {
    setNotificationList([]);
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.listNotificationAll,
        headers: {
          // token: accessToken,
          authorization: `Bearer ${accessToken ? accessToken : token}`,
        },
      });

      if (res.data.status === 1618) {
        setNotificationList(res.data.data);

        setUnReadNotification(res.data.data.filter((data) => !data.isSeen));
      } else {
        setNotificationList([]);
      }
    } catch (error) {
      console.log(error);
      setNotificationList([]);
    }
  };

  useEffect(() => {
    if (accessToken) {
      NotificationDataList();
    }
  }, [accessToken]);

  const getKycList = async () => {
    setLoader(true);
    try {
      const res = await axios.get(ApiConfig.getKycList, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      if (res.data.responseCode === 200) {
        setStatusData(res.data.result);
        setLoader(false);
        auth.getProfileHandler();
      }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    getKycList();
  }, []);

  useEffect(() => {
    if (accessToken) {
      getWalletHandler(accessToken);
    }
  }, [accessToken]);

  let data = {
    isLogin,
    yourWalletBalance,
    userBalanceData,
    setUnReadNotification,
    NotificationDataList: () =>
      NotificationDataList(localStorage.getItem("creatturAccessToken")),
    getWalletHandler: () =>
      getWalletHandler(localStorage.getItem("creatturAccessToken")),
    notificationList,
    unReadNotification,

    setStatusData,
    StatusData,
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
