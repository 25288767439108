// const url = "https://java.rehnanceworld.com/"; //live java
const url = "https://java.rehnance.com/"; //staging java

// export const baseUrl = "https://socket.rehnanceworld.com"; //live java
export const baseUrl = "https://socket.rehnance.com"; //steging java
// const url = "http://13.127.125.155:8002/"; //live URL
// export const baseUrl = "http://13.127.125.155:8878";

const user = `${url}account`;

// const user = `${url}/user`;
const wallet = `${url}wallet`;
const static1 = `${url}static`;
const admin = `${url}/admin`;
const notification = `${url}notification`;
const p2pSubscription = `${url}/p2pAdvertisement`;
const paymentMethod = `${url}/payment`;
const contactUsForm = `${url}/contactUs`;
const chat = `${url}/chat`;
const faqs = `${url}/faq`;
const cmc = `${url}/cmc`;
const notificationAll = `${url}/listNotification`;
const appel = `${url}/appeal`;
const ApiConfig = {
  userLogin: `${url}auth`,
  SettlementStatusListTable: `${user}/SettlementStatusList`,
  getCoinList: `${url}/wallet/coin/get-coin-list`,
  userSignUp: `${user}/signup`,
  verifyOTP: `${user}/verify-user`,
  verifyOTPForgot: `${user}/verify-sms-code-mobile-app`,
  resendOTP: `${user}/resendOTP`,
  emailOTPResend: `${user}/resend-verify-email`,
  forgotPassword: `${user}/forget-password/mobiloitApp`, ///verify-sms-code-mobile-app
  // forgotPassword: `${user}/forget-password/mobiloitApp`, ///verify-sms-code-mobile-app
  verifyOTPSMS: `${user}/verify-sms-code-mobile-app`, //verify-sms-code-mobile-app
  resetPassword: `${user}/reset-password-mobile-app`,
  editKYC: `${user}/editKYC`,

  //tokonomicsDetails
  tokonomicsDetails: `${user}/tokonomicsDetails`,

  // editUserProfile: `${user}/profile-update`,
  getCountryList: `${user}/get-country-list`,
  getTransactionHistory: `${url}/wallet/wallet/get-all-transaction-history`,
  newsletter: `${url}newsletter/add`,

  transactionHistoyList: `${wallet}/get-all-transaction-history-USER`,
  addSubscribe: `${user}/add-subscribe`,
  // account
  myAccount: `${user}/my-account`,
  saveKycDetails: `${user}/save-kyc-details`,
  uploadFile: `${user}/uploadImage`,
  getKycList: `${user}/get-kyc-details`,
  editUserProfile: `${user}/profile-update`,
  cmcData: `${cmc}/cmc`,
  userRefferalDashboard: `${user}/get-referal-data`,
  uploadfile: `${user}/upload-file`,
  whitelistPaper: `${user}//get-whitepaper-full-list`,

  // wallet
  wallateGenerator: `${user}/wallateGenerator`,
  myWallet: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
  getStakingList: `${wallet}/get-staking-list`,
  addStaking: `${wallet}/vesting-management/add-staking`,
  getAllTransactionHistory: `${wallet}/get-all-transaction-history-USER`,

  getVirtualDinero: `${user}/getVirtualDenaroValue`,
  // static
  contactUS: `${static1}/submit-support-ticket`,
  addFeedback: `${contactUsForm}/addFeedback`,
  postnewsLetter: `${static1}/post-news-letter-to-user`,
  StaticData: `${static1}/static`,
  TermsConditions: `${static1}/static/TermsConditions`,
  PrivacyPolicy: `${static1}/get-static-page-data`,
  AboutUs: `${static1}/static/AboutUs`,
  faqList: `${static1}/get-faq-list`,
  subscribeToNewsLetter: `${user}/subscribeToNewsLetter`,
  term: `${static1}/get-static-page-data-by-page-key`,
  Aboutus: `${static1}/get-all-static-content-data`,
  policy: `${static1}/get-all-static-content-details-for-web`,
  // policy: `${static1}/get-static-page-data-by-page-key?pageKey=Privacy_Policy`,
  getCurrentprice: `${wallet}/wallet/get-price?`,
  toaddress: `${wallet}/wallet/get-all-coin-with-wallet-address`,
  myAddress: `${wallet}/wallet/get-address`,
  approveWithdraw: `${wallet}/wallet/approve-withdraw`,
  cancelMoney: `${wallet}/wallet/cancel-withdraw`,
  deposits: `${user}/wallet/get-deposits`,
  buyToken: `${wallet}/basic-exchange/buy-token`,
  buyTokenBtc: `${wallet}/wallet/buy-token`,
  minimumfee: `${wallet}/wallet/coin/get-coin-list`,

  // admin /wallet/coin/get-coin-list
  transactionHistoy: `${wallet}/get-all-transaction-history`,
  // notification
  getNotification: `${notification}/get-notification-data`,
  readNotification: `${notification}/clear-notification`,
  // <<<<<<< HEAD
  clearNotification: `${notification}/clear-notification`,
  sendNotificationForP2pExchange: `${notification}/send-notification-for-p2p-exchange`,
  getChatData: `${notification}/get-chat-data`,
  // getChatData: `${notification}/get-chating-data`,
  // =======
  // deleteNotification: `${notification}/delete-notification`,
  // >>>>>>> b005bf469e4657fca4930aa3cff6eeb9be4bf5f3

  // account
  activeStake: `${wallet}/get-list-by-active-status`,

  walletBalance: `${wallet}/wallet/get-all-user-balance-and-coinlist `,

  viewfixedDeposit: `${wallet}/view-fixed-deposit-code`,
  unstake: `${wallet}/set-status-staking-stop`,

  userTransactionHistoy: `${wallet}/get-user-transaction-history`,

  //p2p-exchange
  addAdvertisment: `${p2pSubscription}/p2pAdvertisement`,

  searchAdvertiseFilter: `${p2pSubscription}/search-and-filters-advertisement`,

  p2pAdvertisementList: `${p2pSubscription}/p2pAdvertisement`,
  p2pBlockunblock: `${p2pSubscription}/p2pAdvertisement`,
  DeleteAdvertisement: `${p2pSubscription}/p2pAdvertisement`,
  TradeAdvertisement: `${p2pSubscription}/p2pAdvertisementTrades`,

  showInterestP2PAdvertisement: `${p2pSubscription}/showInterestP2PAdvertisement`,

  getDetailsAfterPressBuyButton: `${p2pSubscription}/get-details-after-press-buy-button`,
  getDetailsAfterPressSellButton: `${p2pSubscription}/get-details-after-press-sell-button`,
  sendMessageAfterCancelTradeButton: `${p2pSubscription}/send-message-after-cancel-trade-button`,
  sendTradeRequest: `${p2pSubscription}/send-trade-request`,
  sendMessageAfterPaidButton: `${p2pSubscription}/send-message-after-paid-button`,
  getCompletedTradeList: `${p2pSubscription}/get-completed-trade-list`,
  viewP2pByUserId: `${p2pSubscription}/view-p2p-by-user-id`,
  searchAndFiltersTradeList: `${p2pSubscription}/search-and-filters-trade-list`,
  searchAndFiltersAdvertisement: `${p2pSubscription}/search-and-filters-advertisement`,
  // DeleteAdvertisement: `${p2pSubscription}/Delete-advertisement`,
  afterPressDisputeButton: `${p2pSubscription}/after-press-dispute-button`,
  confirmP2PAdvertisementPayment: `${p2pSubscription}/confirmP2PAdvertisementPayment`,
  confirmP2PAdvertisementMoneyPaid: `${p2pSubscription}/confirmP2PAdvertisementMoneyPaid`,
  p2pAdvertisementOrders: `${p2pSubscription}/p2pAdvertisementOrders`,

  // change-password

  changepassword: `${user}/change-password`,
  // bank-controller
  listBank: `${user}/listBank`,
  addBank: `${user}/addBank`,
  deleteBank: `${user}/deleteBank`,
  viewBank: `${user}/viewBank`,

  // verification-otp

  googleauth: `${user}/addAuthentication`,

  verifygooglecode: `${user}/verifyTwoAuthentication`,
  twoFadisbaled: `${user}/googleAuthenticationDisable`,

  enableDisableEmailMobileNumberAuth: `${user}/enableDisableEmailMobileNumberAuth`,

  // socket

  // staking
  earnstake: `${wallet}/get-Earnd-details`,
  earnstakeAmount: `${wallet}/get-Earnd-Stake-Amount-details`,
  addStake: `${user}/addStake`,
  viewfixedDeposit: `${user}/listStake`,
  emergencyWithdraw: `${user}/stakeRequest`,
  stakeInterestList: `${admin}/stakeInterestList`,
  stakeDashboard: `${user}/stakeDashboard`,

  // send money and recieve money
  sendMoney: `${wallet}/wallet/withdraw`,
  receiveMoney: `${user}/receiveMoney`,

  // Notification List

  listNotificationAll: `${notification}/get-notification-data`,
  deleteNotificationList: `${notification}/notification`,

  //Payment method

  payment: `${paymentMethod}/payment`,
  // chating
  chatList: `${chat}/chatList`,
  chat: `${chat}/chat/`,
  // appeal
  appel: `${appel}/appeal`,
  appelList: `${appel}/appeal`,
  appelDelete: `${appel}/appeal`,

  payWithCrypto: `${wallet}/vesting-management/buy-token`,

  // swapping
  transferCoinChangely: `${user}/transferCoinChangely`,
  loginHistory: `${user}/get-user-login-details`,
  userRefferalList: `${user}/get-referal-data`,
  subscribeNewsLetter: `${user}/subscribeToNewsLetter`,
  getTokonomics: `${wallet}/get-sale-list`,
};

export default ApiConfig;
