import {
  Typography,
  Box,
  IconButton,
  Link,
  makeStyles,
} from "@material-ui/core";
import React, { useState, useContext } from "react";
import { BsArrowReturnRight, BsArrowReturnLeft } from "react-icons/bs";
import { GoUnverified } from "react-icons/go";

import { AuthContext } from "src/context/Auth";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import { Link as RouterLink, useHistory } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import moment from "moment";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { SiGooglemessages } from "react-icons/si";
import { NotificationAdd } from "@mui/icons-material";
import { MdOutlineNotificationsActive } from "react-icons/md";
const useStyles = makeStyles((theme) => ({
  mainBox: {
    color: theme.palette.text.primary,
  },
}));
export default function NotificationsList({ data, popUp }) {
  console.log("====================================data", data);

  const history = useHistory();
  const [open, setOpen] = useState(false);

  const confirmationHandler = () => {
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <Box
      className={`${classes.mainBox} notication-list`}
      style={
        popUp
          ? {
              borderBottom: "1px solid #ccc",
              padding: "0px 5px 7px",
              position: "relative",
              color: "#000",
            }
          : {
              // borderBottom: "1px solid #ccc",
              padding: "0px 10px 0px",
              position: "relative",
              color: "#000",
              borderBottom: "1px solid #aca7a7",
            }
      }
    >
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"title"}
          desc={"desc"}
          confirmationHandler={confirmationHandler}
        />
      )}
      <Box display="flex" alignItems="center">
        <Box>
          <IconButton style={{ color: "#000 " }}>
            <MdOutlineNotificationsActive style={{ color: "#000 " }} />
          </IconButton>
        </Box>
        <Link style={{ textDecoration: "none", width: "100%" }}>
          <Box style={{ width: "calc(100% - 50px)" }}>
            <Box className="rightPosition d-flex" style={{ marginTop: "6px" }}>
              <Typography
                variant="subtitle2"
                style={
                  popUp
                    ? { color: "#000000", fontFamily: "Poppins" }
                    : { color: "" }
                }
                pt={2}
              >
                {data?.createdAt ? moment(data.createdAt).format("lll ") : "0"}
              </Typography>
            </Box>
            <Box className="width120" style={{ display: "flex" }}>
              {popUp ? (
                <>
                  <Box style={{ cursor: "pointer", display: "flex" }}>
                    <Typography
                      variant="subtitle2"
                      pt={2}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "400px",
                        color: "#000000",
                        fontFamily: "Poppins",
                      }}
                    >
                      {data?.message}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box style={{ cursor: "pointer", display: "flex" }}>
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: "#000000",
                        fontFamily: "Poppins",
                      }}
                      pt={2}
                    >
                      {data?.message}
                    </Typography>
                  </Box>
                  <br />
                </>
              )}
            </Box>
          </Box>
        </Link>
      </Box>
    </Box>
  );
}
